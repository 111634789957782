import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <style>@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');</style>
    <App />
  </React.StrictMode>
);
