import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Page404 } from "./pages/page404";
import { Survey } from "./pages/survey";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Page404 />} />
          <Route path=":id" element={<Survey />}>
          </Route>
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
