import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "./loading";
import { Page404 } from "./page404";
import { getSurvey, saveAnswer, updateAnswer } from "../api";
import Slider from "@mui/material/Slider";
import ThumbUpSharpIcon from "@mui/icons-material/ThumbUpSharp";
import ThumbDownSharpIcon from "@mui/icons-material/ThumbDownSharp";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const getPrimaryColor = (survey) => {
  return survey?.primary_color || "rgb(50, 142, 205)";
};

const getSecondaryColor = (survey) => {
  return survey?.secondary_color || "rgb(148, 235, 255)";
};

const getCompanyLogo = (survey) => {
  return (
    survey.company_logo ||
    "https://cinchio-logos.s3.amazonaws.com/6/Artboard_2_copy.png?1512149267"
  );
};

const ANSWER_OPTIONS = {
  excellent_to_poor: ["Excellent", "Good", "Average", "Fair", "Poor"],
  highly_to_unlikely: [
    "Highly Likely",
    "Somewhat Likely",
    "Neutral",
    "Somewhat Unlikely",
    "Highly Unlikely",
  ],
  yes_no: ["Yes", "No"],
  one_to_five: ["Five", "Four", "Three", "Two", "One"],
  thumbs_up_down: ["Thumbs Up", "Thumbs Down"],
  smiley_frowney: ["Satisfied", "Dissatisfied"],
};

const SLIDER_ANSWER_OPTIONS = {
  one_to_ten: {
    marks: [
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" },
      { value: 5, label: "5" },
      { value: 6, label: "6" },
      { value: 7, label: "7" },
      { value: 8, label: "8" },
      { value: 9, label: "9" },
      { value: 10, label: "10" },
    ],
    min: 1,
    max: 10,
  },
};

export const Survey = () => {
  const { id: inviteID } = useParams();

  const [status, setStatus] = useState("loading");
  const [survey, setSurvey] = useState();
  const [questionIndex, setQuestionIndex] = useState(0);

  const question =
    status !== "completed" && survey && survey.questions[questionIndex];
  const hasPrev = status !== "completed" && questionIndex > 0;
  const hasNext =
    status !== "completed" &&
    survey &&
    questionIndex < survey.questions.length - 1;

  /*
    Note: The available option results cannot be boolean
          as this would make isAnswered return falsey on false choices.
          This effects whether the "Next" button is clickable or not.
  */
  const isAnswered = question && !!question.answer && !!question.answer.answer;

  useEffect(() => {
    getSurvey(inviteID)
      .then(({ data: survey }) => {
        const questionIndex = survey.questions.findIndex((q) => !q.answer);
        if (questionIndex === -1) {
          // We have no unanswered questions. Either they're all answered or for some reason this
          // survey has no questions.
          setSurvey(survey);
          setStatus("completed");
          return;
        }

        setQuestionIndex(questionIndex);
        setSurvey(survey);
        setStatus("loaded");
      })
      .catch(() => setStatus("not-found"));
  }, [inviteID]);

  const cleanNumericStrings = (answer) => {
    /**
     * I don't like doing it this way but it's the fastest
     * way without taking a bat to the current code.
     *
     * There should be no instance in which we want non castable
     * numeric answers in the text field.
     */
    switch (answer) {
      case "One":
        return "1";
      case "Two":
        return "2";
      case "Three":
        return "3";
      case "Four":
        return "4";
      case "Five":
        return "5";
      case "Six":
        return "6";
      case "Seven":
        return "7";
      case "Eight":
        return "8";
      case "Nine":
        return "9";
      case "Ten":
        return "10";
      default:
        return answer;
    }
  };

  const setAnswer = (answer) => {
    const updatedSurvey = JSON.parse(JSON.stringify(survey));
    if (!updatedSurvey.questions[questionIndex].answer) {
      updatedSurvey.questions[questionIndex].answer = {
        answer,
      };
    } else {
      updatedSurvey.questions[questionIndex].answer.answer = answer;
    }

    setSurvey(updatedSurvey);
  };

  const goBack = () => {
    if (!hasPrev) {
      return;
    }
    setQuestionIndex(questionIndex - 1);
  };

  const goForward = () => {
    if (
      question.q_type !== "comment" &&
      (!question.answer || !question.answer.answer)
    ) {
      return;
    }

    let promise;

    // Save answer
    if (question.answer && question.answer.id) {
      promise = updateAnswer(
        inviteID,
        question.answer.id,
        question.answer.answer
      );
    } else if (question.answer) {
      promise = saveAnswer(
        inviteID,
        question.id,
        cleanNumericStrings(question.answer.answer)
      );
    } else {
      promise = saveAnswer(inviteID, question.id, null);
    }

    promise.then(({ data: answer }) => {
      const updatedSurvey = JSON.parse(JSON.stringify(survey));
      updatedSurvey.questions[questionIndex].answer = answer;
      setSurvey(updatedSurvey);
      if (hasNext) {
        // If there's another question move there
        setQuestionIndex(questionIndex + 1);
      } else {
        // If there's not another question finish the survey
        setStatus("completed");

        if (survey.redirect_conditions) {
          // Check our conditional redirects
          const redirect = survey.redirect_conditions.find((condition) => {
            const question = updatedSurvey.questions.find(
              (question) => question.id === condition.question
            );
            return condition.options.includes(`${question.answer.answer}`);
          });

          if (redirect) {
            window.location.href = redirect.url;
            return;
          }
        }

        if (survey.redirect_url) {
          window.location.href = survey.redirect_url;
          return;
        }
      }
    });
  };

  const valuetext = (value) => {
    return `${SLIDER_ANSWER_OPTIONS[question.q_type].marks[value - 1].label}`;
  };

  if (status === "loading") {
    return <Loading />;
  }

  if (status === "not-found") {
    return <Page404 />;
  }

  return (
    <div id="survey">
      <div className="header">
        <div className="logo">
          <img src={getCompanyLogo(survey)} alt="Company Logo" />
        </div>
        <h1 className="title" style={{ color: getPrimaryColor(survey) }}>
          Survey Questions
        </h1>
        <div className="progress">
          {survey.questions.map((q) => (
            <div
              key={q.id}
              className={`bar ${
                question && q.id === question.id ? "active" : ""
              }`}
              style={{
                background:
                  question && q.id === question.id
                    ? getPrimaryColor(survey)
                    : getSecondaryColor(survey),
              }}
            ></div>
          ))}
        </div>
        <p className="question">
          {question ? question.q_text : "Thank you for completing this survey."}
        </p>
      </div>
      <div className="footer">
        {!!question && (
          <>
            <div className="answers">
              {!!SLIDER_ANSWER_OPTIONS[question.q_type] && (
                <div className="slider">
                  <Slider
                    aria-label={question.q_type}
                    getAriaValueText={valuetext}
                    valueLabelFormat={valuetext}
                    valueLabelDisplay="on"
                    value={
                      JSON.parse(JSON.stringify(survey))?.questions[
                        questionIndex
                      ]?.answer?.answer || 1
                    }
                    step={1}
                    marks={SLIDER_ANSWER_OPTIONS[question.q_type].marks}
                    min={SLIDER_ANSWER_OPTIONS[question.q_type].min}
                    max={SLIDER_ANSWER_OPTIONS[question.q_type].max}
                    sx={{
                      color: getPrimaryColor(survey),
                      "& .MuiSlider-thumb": {
                        color: getSecondaryColor(survey),
                      },
                    }}
                    onChange={(event, value) => {
                      setAnswer(
                        SLIDER_ANSWER_OPTIONS[question.q_type].marks[value - 1]
                          .label
                      );
                    }}
                  />
                </div>
              )}
              {!!ANSWER_OPTIONS[question.q_type] &&
                ANSWER_OPTIONS[question.q_type].map((possibleAnswer, index) => {
                  return (
                    <div
                      key={`${possibleAnswer}${index}`}
                      className={`item ${
                        question.answer &&
                        question.answer.answer === possibleAnswer
                          ? "active"
                          : ""
                      }`}
                      onClick={() => setAnswer(possibleAnswer)}
                    >
                      <>
                        {question.q_type === "thumbs_up_down" &&
                          possibleAnswer === "Thumbs Up" && (
                            <ThumbUpSharpIcon />
                          )}{" "}
                        {question.q_type === "thumbs_up_down" &&
                          possibleAnswer === "Thumbs Down" && (
                            <ThumbDownSharpIcon />
                          )}
                        {question.q_type === "smiley_frowney" &&
                          possibleAnswer === "Satisfied" && (
                            <SentimentSatisfiedAltIcon />
                          )}{" "}
                        {question.q_type === "smiley_frowney" &&
                          possibleAnswer === "Dissatisfied" && (
                            <SentimentVeryDissatisfiedIcon />
                          )}
                        {/**
                         * This is might be a sign we want to
                         * start splitting this all up into components.
                         * It's a bit difficult to see what's going on / manage for different surveys.
                         */}
                        {!!question.answer &&
                          question.answer.answer === possibleAnswer &&
                          question.q_type !== "thumbs_up_down" &&
                          question.q_type !== "smiley_frowney" && (
                            <div className="check">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="9226 -3088.877 20.877 20.877"
                              >
                                <g
                                  id="Group_378"
                                  transform="translate(9177 -3420)"
                                >
                                  <g
                                    id="Ellipse_113"
                                    className="checkmark-1"
                                    transform="translate(49 331.123)"
                                  >
                                    <circle
                                      className="checkmark-3"
                                      cx="10.439"
                                      cy="10.439"
                                      r="10.439"
                                    ></circle>
                                    <circle
                                      className="checkmark-4"
                                      cx="10.439"
                                      cy="10.439"
                                      r="9.939"
                                    ></circle>
                                  </g>
                                  <g id="Group_377">
                                    <path
                                      id="Path_45"
                                      className="checkmark-2 "
                                      d="M335,161.8l-2.1-2.1a.636.636,0,1,1,.9-.9l2.1,2.1a.636.636,0,1,1-.9.9Z"
                                      transform="translate(-278 183)"
                                    ></path>
                                  </g>
                                  <g id="Group_376">
                                    <path
                                      id="Path_46"
                                      className="checkmark-2 "
                                      d="M341,156.8l-5,5a.636.636,0,1,1-.9-.9l5-5a.636.636,0,0,1,.9.9Z"
                                      transform="translate(-278 183)"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          )}
                        {possibleAnswer}
                      </>
                    </div>
                  );
                })}
              {question.q_type === "comment" && (
                <textarea
                  value={
                    JSON.parse(JSON.stringify(survey))?.questions[questionIndex]
                      ?.answer?.answer || ""
                  }
                  onChange={(e) => setAnswer(e.target.value)}
                  placeholder="Enter response here..."
                />
              )}
            </div>
            <div className="buttons">
              {hasPrev && (
                <div className="prev-button" onClick={() => goBack()}>
                  Back
                </div>
              )}
              {!isAnswered && question.q_type === "comment" ? (
                <div className="next-button active" onClick={() => goForward()}>
                  No Comment
                </div>
              ) : (
                <div
                  className={`next-button ${isAnswered ? "active" : ""}`}
                  onClick={() => goForward()}
                >
                  {hasNext ? "Next" : "Finish"}
                </div>
              )}
            </div>
          </>
        )}
        <div className="powered">
          powered by{" "}
          <img
            src="https://portal.cinch.io/images/cinch_logo.svg"
            alt="Cinch"
          />
        </div>
      </div>
    </div>
  );
};
